import React from 'react'
import Layout from '../../components/Layout'
import Search from '../../components/Search'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import IconLink from "../../components/Common/StyledButton/IconLink";
import Seo from "gatsby-plugin-wpgraphql-seo";

const ResourcesResultsPage = ({ location }) => {
  return (
		<Layout>
			<Seo title="Search" />
			<Container fluid="lg">
				<Row className="pt-4">
					<Col className="pb-5 article-meta">
						<IconLink
							align="right"
							cta="Back to Resources"
							href="/resources/"
							size="small"
							iconColor="orange"
							name="left"
						/>
					</Col>
				</Row>
			</Container>
			<Search
				location={location}
				showResourceFilters={true}
				showPostTypeFilters={false}
				postType="post"
        hasBackBtn={true}
			/>
		</Layout>
	)
}

export default ResourcesResultsPage